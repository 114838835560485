import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { ChartBaseItem } from 'gql/pool/__generated__/request';
import Numeral from 'numeral';
export function formatDateTime(timestamp: number): string {
  return dayjs(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');
}

export const fileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

export function isLocal() {
  return (
    ['https://localhost', 'https://127.0.0.1'].indexOf(
      window.location.origin,
    ) >= 0
  );
}

export function formatNumber(num?: number | string, roundDown: boolean = true) {
  if (roundDown) {
    num = formatRounddownNumber(num || 0);
  }
  if (num && Number(num) > 1000000) {
    return Numeral(num).format(' 0.[00]a').toLocaleUpperCase();
  }

  return Numeral(num).format('0,0.[00]');
}

export function formatSpeed(num?: number | string, decimals: number = 2) {
  const n = Number(num) || 0;
  if (n < 1000) {
    return `${num}`;
  } else if (n < 1e6) {
    return `${(n / 1000).toFixed(decimals)} K`;
  } else if (n < 1e9) {
    return `${(n / 1e6).toFixed(decimals)} M`;
  } else if (n < 1e12) {
    return `${(n / 1e9).toFixed(decimals)} G`;
  } else if (n < 1e15) {
    return `${(n / 1e12).toFixed(decimals)} T`;
  } else if (n < 1e18) {
    return `${(n / 1e15).toFixed(decimals)} P`;
  } else if (n < 1e21) {
    return `${(n / 1e18).toFixed(decimals)} E`;
  } else if (n < 1e24) {
    return `${(n / 1e21).toFixed(decimals)} Z`;
  } else {
    return `${(n / 1e24).toFixed(decimals)} Y`;
  }
}

export function formatRounddownNumber(num: Number | string, decimal = 2) {
  return Number(new BigNumber(Number(num)).toFixed(decimal, 1));
}

export interface ChartOutputData {
  x: number[];
  y1: number[];
  y2: number[];
  y3: number[];
  y1Average: number;
  y2Average: number;
  y3Average: number;
}
export function getChartDataWithInput(
  inputY1: ChartBaseItem[],
  inputY2: ChartBaseItem[],
  inputY3: ChartBaseItem[],
): ChartOutputData {
  const defaultReturn = {
    x: [],
    y1: [],
    y2: [],
    y3: [],
    y1Average: 0,
    y2Average: 0,
    y3Average: 0,
  };
  if (!inputY1.length && !inputY2.length && !inputY3.length) {
    return defaultReturn;
  }

  const y1: number[] = [];
  const y2: number[] = [];
  const y3: number[] = [];
  const compareArray = [inputY1, inputY2, inputY3];
  const compareArrayLengths = compareArray.map((item) => item.length);
  const maxLengthIndex = compareArrayLengths.findIndex(
    (item) => item === compareArrayLengths.sort((a, b) => b - a)[0],
  );
  const x: number[] = [];
  const indexArray = compareArray[maxLengthIndex];
  if (!indexArray?.length) return defaultReturn;
  for (let i = 0; i < indexArray.length; i++) {
    x.push(Number(indexArray[i].timestamp));
    y1.push(Number(inputY1[i]?.value || 0));
    y2.push(Number(inputY2[i]?.value || 0));
    y3.push(Number(inputY3[i]?.value || 0));
  }

  return {
    x,
    y1,
    y2,
    y3,
    y1Average:
      y1.reduce(function (acr, cur) {
        return acr + cur;
      }) / indexArray.length,
    y2Average:
      y2.reduce(function (acr, cur) {
        return acr + cur;
      }) / indexArray.length,
    y3Average:
      y3.reduce(function (acr, cur) {
        return acr + cur;
      }) / indexArray.length,
  };
}

export function formatHashValue(address?: string) {
  if (!address) {
    return;
  }
  const prev4 = address.substring(0, 4);
  const last4 = address.substring(address.length - 4, address.length);
  return `${prev4}...${last4}`;
}

export function uppercaseFirstStr(str: string) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}
