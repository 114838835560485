import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { grid } from 'ldrs';
import { formatSpeed } from 'utils';
import {
  UserWorkerQuery,
  UserWorkersQuery,
  WorkerBaseInfo,
} from 'gql/pool/__generated__/request';
import moment from 'moment';
import Loading from 'components/Loading';
import { poolRequestApi } from 'gql';
import user from 'stores/user';
import { RowCenter } from 'components/Row';
import ChartTMP1, { ChartTMP1Props } from 'components/Chart/tmp1';

const Wrapper = styled.div`
  width: 100%;
  min-width: 600px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    min-width: unset;
    background: rgba(0, 0, 0, 0.3);
    `};
`;

interface Props {
  userId?: string;
  workerName: string;
  isShow?: boolean;
}
export default function SpeedChart({ userId, workerName, isShow }: Props) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<UserWorkerQuery>();

  async function getUserWorkerInfo() {
    setIsLoading(true);
    const date = new Date();
    const minutes = date.getMinutes();
    const roundedMinutes = Math.floor(minutes / 10) * 10;
    date.setMinutes(roundedMinutes);
    const res = await poolRequestApi.userWorker({
      userId: userId || '',
      workerName,
      startTime: moment(date).add('days', -1).valueOf(),
    });
    if (res) {
      setData(res);
    }
    setIsLoading(false);
    return res;
  }

  useEffect(() => {
    if (isShow) {
      getUserWorkerInfo();
    }
  }, [isShow, workerName, userId]);

  const speedProps: ChartTMP1Props = useMemo(() => {
    return {
      isLoading: isLoading,

      chartName: {
        y1: t('Latest 24 Hrs Speed'),
      },
      d1: {
        data: data?.userWorker?.workerCharts?.poolComputeValuesCharts || [],
        name: t('Speed(Estimated)'),
      },
      d2: {
        data: data?.userWorker?.workerCharts?.localComputeValuesCharts || [],
        name: t('Local Speed'),
      },
    };
  }, [isLoading, data]);

  return (
    <Wrapper
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      <ChartTMP1 {...speedProps} />
    </Wrapper>
  );
}
