import { DiscordSVG, TelegramSVG, XSVG } from 'assets';
import { isCommunity } from 'const';
import React from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import { default as MayaLogo } from 'assets/img/logos/mayafooter.svg';

const Wrapper = styled.div`
  position: relative;
  width: 1220px;
  margin: 0 auto;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  padding: 0 2rem;
  padding-bottom: 40px;
  `};
`;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  justify-content: space-between;
  `}
`;
// const P1XLogo = styled.img`
//   width: 120px;
//   height: 77px;
//   flex-shrink: 0;

//   ${({ theme }) => theme.mediaWidth.upToSmall`
//   width: 82px;
//   height: 53px;
//   `}
// `;

const Links = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  ${({ theme }) => theme.mediaWidth.upToSmall`

    margin-top:40px;
    `}
`;

const MobileLinks = styled.div`
  display: none;
  ${({ theme }) => theme.mediaWidth.upToSmall`

display: flex;
`}
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
`;

const Link = styled.a`
  text-decoration: none;
  font-size: 18px;
  color: #fff;
  margin-right: 88px;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-right: 20px;
  font-size: 14px;
  `}
`;

const ContactUs = styled.div`
  width: 176px;
  height: 50px;
  border-radius: 6px;
  border: 1px solid;
  color: #00f4e5;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-image: linear-gradient(
      223deg,
      rgba(97, 232, 130, 1),
      rgba(0, 218, 204, 1),
      rgba(32, 175, 235, 1)
    )
    1 1;
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 116px;
    height: 36px;
    font-size: 12px;
  `}
`;

const Border = styled.div`
  width: 100%;
  height: 2px;
  border: 1px solid;
  margin: 20px 0;
  border-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      #22d3ee,
      rgba(255, 255, 255, 0)
    )
    1 1;
`;

const NavBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 48px;

  a {
    font-size: 16px;
    text-decoration: none;
    color: #a1a1aa;
  }
`;

const MediaBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 48px;
  margin-top: 40px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  svg {
    width: 25px;
    height: 20px;
    color: #fff;
  }
`;

const StyledLogo = styled(MayaLogo)``;

export default function MayaFooter() {
  return (
    <Wrapper>
      <StyledLogo />
      {/* <NavBox>
        <a href="/">Home</a>
        <a href="/product">Product</a>
        <a href="/company">Company</a>
        <a href="/writings">Writings</a>
        <a href="/explorer">explorer</a>
      </NavBox> */}
      {/* <MediaBox>
        <a href="" target="_blank" rel="noreferrer">
          <XSVG />
        </a>
        <a href="" target="_blank" rel="noreferrer">
          <TelegramSVG />
        </a>
        <a href="" target="_blank" rel="noreferrer">
          <DiscordSVG />
        </a>
      </MediaBox> */}
      <TYPE.desc fontSize={14} mobileFz={12} marginTop="30px">
        Copyrights MAYA All rights Reserved.
      </TYPE.desc>
    </Wrapper>
  );
}
