import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { TYPE } from 'theme';
import { RowBetween, RowCenter } from 'components/Row';
import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react';
import user from 'stores/user';

import MinerTable from './MinerTable';
import SearchMiner from 'components/SearchMiner';
import PageTitle from 'components/PageTItle';
import Copy from 'components/Copy';
import { RemoveSVG } from 'assets';
import Overview from './Overview';
import Income from './Income';
import classNames from 'classnames';
import { isMaya } from 'const';
import Tabs, { Tab } from 'components/Page/Tab';

export type Person = {
  firstName: string;
  lastName: string;
  email: string;
  city: string;
};

const Wrapper = styled.div`
  padding: 10px;
  .MuiBreadcrumbs-separator {
    color: #fff;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => theme.c.t02};
  text-decoration: none;
`;

const Border = styled.div`
  width: 100%;
  height: 2px;
  border: 1px solid;
  margin: 20px 0;
  border-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.48),
      rgba(255, 255, 255, 0)
    )
    1 1;
`;

const ContentBox = styled.div`
  width: 100%;
  display: none;
  margin-top: 40px;
  &.maya {
    margin-top: 0 !important;
    &.show {
      animation: none;
    }
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-top: 20px;
  `};

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0px);
    }
  }

  &.show {
    display: block;
    animation: fadeIn 0.5s;
  }
`;

const AddressWrapper = styled.div`
  width: 960px;
  height: 88px;
  background: linear-gradient(90deg, #349da6 0%, #3a1d52 100%);
  &.maya {
    background: #091a33;
  }
  border-radius: 20px 20px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin: 0 auto;
  margin-top: 80px;
  padding: 10px 20px;

  .address {
    word-wrap: break-word;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  height: fit-content;
  margin-top: 40px;
  padding: 10px 20px;
  gap: 10px;
  `};
`;

const StyledRemoveSVG = styled(RemoveSVG)`
  cursor: pointer;
  flex-shrink: 0;
`;

const StyledAddressButtonWrapper = styled(RowCenter)`
  width: fit-content;
  gap: 20px;
  flex-shrink: 0;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  gap: 8px;
 `};
`;

function Miners() {
  const { id } = useParams<{ id: string }>();
  const [tab, setTab] = useState(0);
  const [searchAddress, setSearchAddress] = useState<string>(id);
  const { t } = useTranslation();
  const history = useHistory();
  const [isShowSearch, setIsShowSearch] = useState(false);

  useEffect(() => {
    user.setId(id);
    setSearchAddress(id);

    return () => {
      user.clearData();
    };
  }, [id]);

  function search() {
    if (searchAddress) {
      history.push(`/machine/${searchAddress}`);
    }
  }
  return (
    <Wrapper>
      <PageTitle hideText={isShowSearch} />
      <AddressWrapper className={classNames(isMaya && 'maya')}>
        <TYPE.main
          fontSize={20}
          mobileFz={16}
          fontWeight={600}
          textAlign={'center'}
          style={{ flexGrow: 1 }}
          className="address"
        >
          {id}
        </TYPE.main>
        <StyledAddressButtonWrapper>
          <Copy text={id} />
          <StyledRemoveSVG
            onClick={() => {
              setIsShowSearch(true);
            }}
          />
        </StyledAddressButtonWrapper>
      </AddressWrapper>
      {isShowSearch ? (
        <SearchMiner
          active
          onCompleteSearch={() => {
            setIsShowSearch(false);
          }}
        />
      ) : (
        <></>
      )}
      <Tabs>
        <Tab
          className={tab === 0 ? 'active' : ''}
          onClick={() => {
            setTab(0);
          }}
        >
          <TYPE.main fontSize={20} mobileFz={16} fontWeight={600} uppercase>
            {t('Overview')}
          </TYPE.main>
        </Tab>
        <Tab
          className={tab === 1 ? 'active' : ''}
          onClick={() => {
            setTab(1);
          }}
        >
          <TYPE.main fontSize={20} mobileFz={16} fontWeight={600} uppercase>
            {t('Workers')}
          </TYPE.main>
        </Tab>
        <Tab
          className={tab === 2 ? 'active' : ''}
          onClick={() => {
            setTab(2);
          }}
        >
          <TYPE.main fontSize={20} mobileFz={16} fontWeight={600} uppercase>
            {t('Income')}
          </TYPE.main>
        </Tab>
      </Tabs>

      <ContentBox
        className={classNames(tab === 0 ? 'show' : '', isMaya && 'maya')}
      >
        <Overview />
      </ContentBox>
      <ContentBox
        className={classNames(tab === 1 ? 'show' : '', isMaya && 'maya')}
      >
        <MinerTable />
      </ContentBox>
      <ContentBox
        className={classNames(tab === 2 ? 'show' : '', isMaya && 'maya')}
      >
        <>{tab === 2 && <Income />}</>
      </ContentBox>
    </Wrapper>
  );
}

export default observer(Miners);
