import classNames from 'classnames';
import { RowCenter } from 'components/Row';
import { isCommunity, isVIP, isMaya } from 'const';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TYPE } from 'theme';

const Wrapper = styled.div`
  &.hideText {
    div {
      color: #000 !important;
      -webkit-text-fill-color: #000 !important;
    }
  }
`;
export default function PageTitle({
  className,
  hideText,
}: {
  className?: string;
  hideText?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Wrapper className={classNames(className, hideText ? 'hideText' : '')}>
      <RowCenter>
        {isCommunity && (
          <>
            <TYPE.gMain fontSize={64} mobileFz={32}>
              CYSIC
            </TYPE.gMain>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <TYPE.cGMain fontSize={64} mobileFz={32}>
              ALEO POOL
            </TYPE.cGMain>
          </>
        )}
        {isMaya && (
          <>
            <TYPE.gMain fontSize={64} mobileFz={32}>
              MAYA
            </TYPE.gMain>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <TYPE.cGMain fontSize={64} mobileFz={32}>
              POOL
            </TYPE.cGMain>
          </>
        )}
        {isVIP && (
          <>
            <TYPE.cGMain fontSize={64} mobileFz={32}>
              ALEO POOL
            </TYPE.cGMain>
          </>
        )}
      </RowCenter>
      <TYPE.gMain
        fontSize={32}
        mobileFz={22}
        marginTop={16}
        textAlign={'center'}
        style={{ textTransform: 'uppercase' }}
      >
        {t(isCommunity ? 'pooldesc' : isMaya ? 'MayaDesc' : 'pooldescVIP')}
      </TYPE.gMain>
    </Wrapper>
  );
}
