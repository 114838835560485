{
  "pooldesc": "究極のパフォーマンスのために高度に最適化されたプール",
  "pooldescVIP": "究極のパフォーマンスを実現する超最適化プール",
  "Network Speed": "ネットワーク速度",
  "Pool Speed": "プールスピード",
  "Pool rewards": "プール報酬",
  "Miners count": "鉱夫たちは数えます",
  "Solutions count": "ソリューション数",
  "Workers count": "ワーカー数",
  "Search Miner": "マイナーを検索",
  "Enter Miner Address": "マイナーアドレスを入力",
  "Search": "検索",
  "Real-time speed": "リアルタイムの速度",
  "Today mined": "今日採掘された",
  "Worker Name": "ワーカー名",
  "Speed": "速度",
  "Submitted Solutions": "提出された解決策",
  "Last submit time": "最終提出時刻",
  "Online Status": "オンラインステータス",
  "Settlement day": "決済日",
  "Amount": "量",
  "Home": "家",
  "All": "すべて",
  "Online": "オンライン",
  "Offline": "オフライン",
  "Invalid": "無効です",
  "Miners": "鉱夫",
  "Income": "収入",
  "Total Income": "総収入",
  "Account Balance": "口座残高",
  "Today’s Revenue(Estimated)": "本日の収益(予想)",
  "No records": "レコードなし",
  "Miner": "坑夫",
  "runCmdJoin": "ターミナルで次のコマンドを実行して、プールに参加します",
  "Pool": "プール",
  "homepageBottomDesc": "Cysic Networkは、ZK革命をハイパースケールするZKプルーフレイヤーです。その主な目的は、Cysicの主要なCUDAおよびASIC開発、およびコミュニティからのコンピューティングリソースを使用して、すべてのZKプロジェクトのZKプルーフの生成と決済を迅速化することです。",
  "Workers": "勤務者",
  "Total Workers": "総労働者数",
  "Current Epoch": "現在のエポック",
  "Block Height": "ブロックの高さ",
  "Workers Count": "労働者数",
  "somethingWrong": "何か問題があります。後でもう一度お試しください。",
  "Miner does not exist": "マイナーが存在しません",
  "Address not found": "住所が見つかりません",
  "Total solutions": "トータルソリューション",
  "Join": "接続",
  "Rows per page": "ページあたりの行数",
  "Detailed tutorial": "詳細なチュートリアル",
  "Total Payouts": "合計ペイアウト",
  "Search History": "検索履歴",
  "How to join": "参加方法",
  "No more data": "これ以上のデータはありません",
  "Sort by": "並び替え",
  "Pool Fee": "プール料金",
  "Search by worker name": "マイナー名で検索",
  "Reduce Pool Fee": "プール料金の削減",
  "Local Speed": "ローカルスピード",
  "Speed(Estimated)": "速度（推定）",
  "Language": "言語",
  "More": "もっと",
  "Latest 24 Hrs Speed": "最新の24時間速度",
  "Paid": "有料",
  "Unpaid": "未払いの",
  "Average": "平均",
  "Overview": "概要",
  "Latest Pool Speed": "最新のプール速度",
  "Pool Rewards": "プール報酬",
  "Pool Solutions": "プールソリューション",
  "Latest 24 Hrs Solutions": "最新の24時間ソリューション",
  "Shares": "株式",
  "Latest 14 Days Income": "最新の14日間の収入",
  "Settlement": "解決",
  "Payments": "支払い",
  "Payment Day": "支払い日",
  "Transaction Hash": "トランザクションハッシュ",
  "Error": "エラー",
  "Pending": "ペンディング",
  "Success": "成功",
  "Status": "地位",
  "Rewards": "報酬",
  "Solutions Counts": "解決策はカウントされます",
  "Loading": "積載",
  "Total Solutions": "トータルソリューション",
  "MayaDesc": "グローバルデジタルアセットサービスプラットフォーム",
  "Solutions": "ソリューション",
  "On-chain Pool Speed": "オンチェーンプール速度",
  "Pool Solution Counts": "プールソリューションの数",
  "Network": "ネットワーク",
  "est100MRewards": "Est.100M s/sリワード"
}