{
  "pooldesc": "高度优化的矿池，实现极致性能",
  "pooldescVIP": "超优化的矿池以实现终极性能",
  "Network Speed": "全网算力",
  "Pool Speed": "矿池算力",
  "Pool rewards": "矿池奖励",
  "Miners count": "矿工计数",
  "Solutions count": "提交数量",
  "Workers count": "矿机数量",
  "Search Miner": "矿工查询",
  "Enter Miner Address": "输入矿工地址",
  "Search": "查询",
  "Real-time speed": "实时算力",
  "Today mined": "今日已挖",
  "Worker Name": "矿机名称",
  "Speed": "速度",
  "Submitted Solutions": "权益价值",
  "Last submit time": "最近提交时间",
  "Online Status": "在线状态",
  "Settlement day": "结算日期",
  "Amount": "数量",
  "Home": "主页",
  "All": "所有",
  "Online": "在线",
  "Offline": "离线",
  "Invalid": "失效",
  "Miners": "矿机",
  "Income": "收入",
  "Total Income": "总收入",
  "Account Balance": "账户余额",
  "Today’s Revenue(Estimated)": "今日已挖（预估）",
  "No records": "暂无记录",
  "Miner": "矿工",
  "runCmdJoin": "在终端中运行以下命令以加入池",
  "Pool": "矿池",
  "homepageBottomDesc": "Cysic Network 是一个 ZK 证明层，它超大规模地推动了 ZK 革命。它的主要目的是利用 Cysic 领先的 CUDA 和 ASIC 开发，以及来自社区的计算资源，以加快所有 ZK 项目的 ZK 证明生成和结算。",
  "Workers": "矿机",
  "Total Workers": "总矿机数量",
  "Current Epoch": "当前Epoch",
  "Block Height": "区块高度",
  "Workers Count": "矿机数量",
  "somethingWrong": "出错，请稍后再试",
  "Miner does not exist": "矿工不存在",
  "Address not found": "找不到地址",
  "Total solutions": "总共解决",
  "Join": "加入",
  "Rows per page": "每页行数",
  "Detailed tutorial": "详细教程",
  "Total Payouts": "总支付",
  "Search History": "检索历史",
  "How to join": "加入矿池",
  "No more data": "没有更多数据",
  "Sort by": "排序方式",
  "Pool Fee": "矿池费率",
  "Search by worker name": "按矿机名称搜索",
  "Reduce Pool Fee": "降低矿池费用",
  "Local Speed": "本地速度",
  "Speed(Estimated)": "速度（估计）",
  "Language": "语言",
  "More": "更多",
  "Latest 24 Hrs Speed": "最新 24 小时速度",
  "Paid": "支付",
  "Unpaid": "未付款的",
  "Average": "平均",
  "Overview": "总览",
  "Latest Pool Speed": "最新矿池速度",
  "Pool Rewards": "矿池奖励",
  "Pool Solutions": "矿池解决方案",
  "Latest 24 Hrs Solutions": "最新的 24 小时解决方案",
  "Shares": "股票",
  "Latest 14 Days Income": "最近 14 天收入",
  "Settlement": "结算",
  "Payments": "付款",
  "Payment Day": "付款日",
  "Transaction Hash": "交易哈希",
  "Error": "错误",
  "Pending": "进行中",
  "Success": "成功",
  "Status": "状态",
  "Rewards": "奖励",
  "Solutions Counts": "解决方案计数",
  "Loading": "加载中",
  "Total Solutions": "总共解决",
  "MayaDesc": "全球数字资产服务平台",
  "Solutions": "解决方案",
  "On-chain Pool Speed": "链上矿池速度",
  "Pool Solution Counts": "矿池解决方案计数",
  "Network": "主网",
  "est100MRewards": "预估100M s/s奖励",
  "Join a": "加入"
}