import styled from 'styled-components';
import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { isMaya } from 'const';

const StyleTabWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  gap: 30px;
  width: 100%;
  margin-top: 60px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  margin-top: 30px;
  `};

  &.maya {
    gap: 0;
    padding: 0;
    justify-content: flex-start;

    > div {
      background: rgba(255, 255, 255, 0.12);
      border-radius: 20px 20px 0 0;
      div {
        color: #999 !important;
      }

      height: 48px;
      &.active {
        background: #2555ff;
        div {
          color: #fff !important;
          -webkit-background-clip: unset !important;
          -webkit-text-fill-color: unset !important;
          background: none;
        }
        border: none;
      }
    }
  }
`;

export const Tab = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;

  ${({ theme }) => theme.mediaWidth.upToSmall`
   font-size: 14px;
   padding: 10px;
  `};

  &.active {
    div {
      background: linear-gradient(
        90deg,
        rgba(123, 61, 235, 1),
        rgba(47, 208, 238, 1)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
    }
    border-bottom: 2px solid;
    border-image: linear-gradient(
        90deg,
        rgba(123, 61, 235, 1),
        rgba(47, 208, 238, 1)
      )
      4 4;
    div {
      font-weight: 800 !important;
    }
  }
`;

export default function Tabs({ children }: { children: ReactNode }) {
  return (
    <StyleTabWrapper className={classNames(isMaya && 'maya')}>
      {children}
    </StyleTabWrapper>
  );
}
