import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  MRT_ColumnDef,
  MRT_PaginationState,
  MRT_SortingState,
} from 'material-react-table';

import Table from 'components/Table';
import { poolRequestApi } from 'gql';
import {
  IncomeOrderKey,
  OrderDirection,
  PaymentOrderKey,
  PaymentStatus,
  UserIncome,
  UserPayment,
} from 'gql/pool/__generated__/request';
import { formatHashValue, formatNumber, uppercaseFirstStr } from 'utils';
import styled from 'styled-components';
import { TYPE } from 'theme';

export type Person = {
  firstName: string;
  lastName: string;
  email: string;
  city: string;
};

const Link = styled.a`
  color: #fff;
  text-decoration: none;
  &:hover {
    color: #0000ff;
  }
`;

const Status = styled(TYPE.main)`
  &.pending {
    color: #808080;
  }
  &.success {
    color: #008000;
  }
  &.error {
    color: red;
  }
`;

interface Props {
  id: string;
}
export default function PaymentsTable({ id }: Props) {
  const { t } = useTranslation();
  const columns: MRT_ColumnDef<UserPayment>[] = [
    {
      accessorKey: 'timestamp',
      header: t('Payment Day'),
      sortingFn: (a, b) => (a as any).timestamp - (b as any).timestamp,
      accessorFn: (row) => moment(row.timestamp).format('YYYY/MM/DD'),
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      muiTableFooterCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: 'amount', //access nested data with dot notation
      header: t('Amount'),
      accessorFn: (row) => `${formatNumber(row.amount)} Aleo`,
      enableSorting: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      muiTableFooterCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: 'txHash', //access nested data with dot notation
      header: t('Transaction Hash'),
      accessorFn: (row) => {
        if (row.status === PaymentStatus.Pending) {
          return '-';
        }

        return (
          <Link
            target="_blank"
            href={`https://mainnet.aleo123.io/transactionDetail/${row.txHash}`}
          >
            {formatHashValue(row.txHash)}
          </Link>
        );
      },
      enableSorting: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      muiTableFooterCellProps: {
        align: 'center',
      },
    },
    {
      accessorKey: 'status', //access nested data with dot notation
      header: t('Status'),
      accessorFn: (row) => {
        return (
          <Status className={row.status}>
            {t(uppercaseFirstStr(row.status))}
          </Status>
        );
      },
      enableSorting: false,
      muiTableHeadCellProps: {
        align: 'center',
      },
      muiTableBodyCellProps: {
        align: 'center',
      },
      muiTableFooterCellProps: {
        align: 'center',
      },
    },
  ];
  const [data, setData] = useState<UserPayment[]>([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    const fetchData = async () => {
      //   if (!data.length) {
      //     setIsLoading(true);
      //   } else {
      //     setIsRefetching(true);
      //   }
      setIsLoading(true);

      try {
        const res = await poolRequestApi.userPayments({
          userId: id,
          skip: pagination.pageIndex * pagination.pageSize,
          limit: pagination.pageSize,
          orderBy: sorting.length
            ? (sorting[0].id as PaymentOrderKey)
            : undefined,
          orderDirection:
            sorting.length && !sorting[0].desc
              ? OrderDirection.Asc
              : OrderDirection.Desc,
        });

        setData(res?.userPayments?.payments || []);
        setRowCount(res?.userPayments?.total || 0);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    if (!id) {
      setData([]);
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.pageIndex, //re-fetch when page index changes
    pagination.pageSize, //re-fetch when page size changes
    sorting, //re-fetch when sorting changes,
    id,
  ]);

  const tableConfig = {
    columns,
    data,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      isLoading: isLoading && !isRefetching,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
      isRefetching,
    },
  };

  return <Table table={tableConfig as any} />;
}
