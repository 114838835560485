import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  BrowserRouter,
  HashRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { observer } from 'mobx-react';
import Home from 'pages/home';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ComingSoon from 'pages/comingSoon';
import Miners from 'pages/miners';
import GlobalSimpleDialog from 'components/Dialog/GlobalSimpleDialog';
import user from 'stores/user';
import GlobalAlert from 'components/Alert/GlobalAlert';
import classNames from 'classnames';
import { isCommunity, isMaya } from 'const';

import MayaFooter from 'components/Footer/Maya';

const Router: any = process.env.PREVIEW === '1' ? HashRouter : BrowserRouter;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  /* height: 100%; */
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 0;
  box-sizing: border-box;

  overflow-x: hidden;
  box-sizing: border-box;
  background-color: #2b4162;
  background-image: linear-gradient(315deg, #2b4162 0%, #12100e 74%);

  &.white {
    background-color: #fff;
  }

  &.community {
    background-color: #000;
    background-image: unset;
  }
  &.maya {
    background-color: #000;
    background-image: unset;
  }

  position: relative;
  z-index: 1;
`;
const MainView = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  color: white;
  padding-top: 140px;
  padding-bottom: 100px;
  max-width: 1200px;
  margin: 0 AUTO;

  background-size: cover;
  background-position: top center;
  z-index: 3;
  ${({ theme }) => theme.mediaWidth.upToSmall`
     padding-top: 100px;
     padding-bottom: 60px;
    `}

  &.homeView {
    background-image: none;
    padding-bottom: 0;
  }
`;

function App() {
  return (
    <Router>
      <Wrapper
        className={classNames(
          'container',
          isCommunity && 'community',
          isMaya && 'maya',
        )}
      >
        <Header />

        <MainView>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/machine/:id" component={Miners} />
            <Route exact path="/help" component={ComingSoon} />
            {/* <Route exact path="/product" component={Product} />
            <Route exact path="/company" component={Company} />
            <Route exact path="/writing" component={Writing} />
            <Route exact path="/explorer" component={ComingSoon} /> */}
          </Switch>
        </MainView>

        {/* </Web3ReactManager> */}
        <GlobalSimpleDialog />
        <GlobalAlert />
        {/* <FixedLogoutWrapper>
        <Button
          color="secondary"
          onClick={() => {
            user.logout();
            window.location.reload();
          }}
        >
          Logout
        </Button>
      </FixedLogoutWrapper> */}
        {isMaya ? <MayaFooter /> : <Footer />}
      </Wrapper>
    </Router>
  );
}

export default observer(App);
