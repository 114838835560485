export { default as LogoSVG } from './logo.svg';
export { default as MayaLogoSVG } from './img/logos/maya.svg';
export { default as OuterlinkSVG } from './img/outerlink.svg';
export { default as XSVG } from './img/x.svg';
export { default as RightArrowSvg } from './img/rightarrow.svg';
export { default as TelegramSVG } from './img/telegram.svg';
export { default as DiscordSVG } from './img/discord.svg';
export { default as Logo } from './Cysic Logo@2x.png';
export { default as LanguageSVG } from './img/language.svg';
export { default as SearchSVG } from './img/search.svg';
export { default as CopySVG } from './img/copy.svg';
export { default as RemoveSVG } from './img/remove.svg';
export { default as FilterSVG } from './img/filter.svg';
export { default as SelectedSVG } from './img/selected.svg';
export { default as FireImg } from './img/fire.png';
