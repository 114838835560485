import React from 'react';
import S, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import styled, { CSSProperties } from 'styled-components';
import { darken } from 'polished';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { SelectedSVG } from 'assets';
import classNames from 'classnames';
import { isMaya } from 'const';
const Wrapper = styled.div`
  width: fit-content;
  &.maya {
    .MuiInputBase-root {
      background: linear-gradient(90deg, #2f41ff 0%, #22d7ff 100%);
      .MuiSelect-select {
        color: #fff;
      }
    }
  }
`;
const StyledSelect = styled(S)`
  &.MuiInputBase-root {
    border: 1px solid #0064c1;
    background-color: transparent;
    padding-right: 12px;
    background: linear-gradient(90deg, #66cebb 0%, #5eafc1 50%, #876fa6 70%);
    border-radius: 16px 16px 16px 16px !important;
    padding: 10px 20px;
    .MuiSelect-select {
      color: #000;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:focus {
        background: none !important;
      }
    }
    .MuiSelect-root {
      padding: 10px 14px;
      padding-right: 50px;
    }
    .MuiOutlinedInput-notchedOutline {
      border: none;
    }
    color: ${({ theme }) => theme.c.t02};

    font-size: 12px;
    border-radius: 20px;
    &:focus,
    &:hover,
    &:active {
      border: 1px solid ${({ theme }) => darken(0.1, theme.c.t02)};
    }

    &::after,
    &::before {
      border: none !important;
    }
  }
`;

const ArrowDown = styled(KeyboardArrowDownIcon)`
  color: #000 !important;
  /* top: unset !important; */
  right: 4px !important;

  &.maya {
    color: #fff !important;
  }
`;

const CustomOption = styled(MenuItem)`
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  :hover {
    background-color: #e7f3fe !important;
  }

  &.maya {
    &.Mui-selected {
      color: #2f41ff !important;
      background: none !important;
      -webkit-background-clip: unset !important;
      -webkit-text-fill-color: unset !important;
      font-weight: 600;
    }
  }
`;

interface Props {
  value?: number | string;
  items: {
    text: string;
    value: number | string | undefined;
  }[];
  onChange?(value: any): void;
  className?: string;
  placeholder?: string;
  style?: CSSProperties;
  renderValue?: string;
  disablePlaceholder?: boolean;
}

const StyledSelected = styled(SelectedSVG)``;

export default function Select({
  value,
  items,
  onChange,
  className,
  placeholder,
  style,
  renderValue,
  disablePlaceholder = true,
}: Props) {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    if (onChange) {
      onChange(event.target.value as any);
    }
  };
  return (
    <Wrapper className={classNames(isMaya && 'maya')}>
      <StyledSelect
        labelId="switch"
        value={value}
        onChange={handleChange}
        className={className}
        IconComponent={() => {
          return <ArrowDown className={classNames(isMaya && 'maya')} />;
        }}
        variant="standard"
        displayEmpty
        style={style}
        renderValue={(v) => {
          return <div>{items.find((menu) => menu.value === v)?.text}</div>;
        }}
      >
        {!disablePlaceholder && (
          <CustomOption value="">{placeholder}</CustomOption>
        )}

        {items.map((i, index) => (
          <CustomOption
            key={index}
            className={classNames('selectOption', isMaya && 'maya')}
            value={i.value}
          >
            {i.text}
            {value === i.value && <StyledSelected />}
          </CustomOption>
        ))}
      </StyledSelect>
    </Wrapper>
  );
}
