import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { formatSpeed, getChartDataWithInput } from 'utils';
import { ChartBaseItem } from 'gql/pool/__generated__/request';
import moment from 'moment';
import Loading from 'components/Loading';
import { RowCenter } from 'components/Row';
import { isMaya, UIVersion } from 'const';

const Wrapper = styled.div`
  width: 100%;
  min-width: 600px;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    min-width: unset;
    background: rgba(0, 0, 0, 0.3);
    `};
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const SwtichItem = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #0e0905;
  opacity: 0.5;
  cursor: pointer;

  &.onSelected {
    opacity: 1;
    font-weight: bold;
  }
`;

interface DataConfig {
  data: ChartBaseItem[];
  name: string;
  axisLabelFormater?(value: number): string;
  yAxisIndex?: number;
  type?: string;
}

export interface ChartTMP1Props {
  xformater?(value: number): string;
  chartName: {
    y1: string;
    y2?: string;
  };
  d1: DataConfig;
  d2?: DataConfig;
  d3?: DataConfig;
  isLoading: boolean;
  showLoading?: boolean;
  //   tooltipsNameFormater?(value: number): string;
  isOpenDataZoom?: boolean;
  height?: string | number;
  chartStyles?: {
    nameTextStyle?: {
      y1?: any;
      y2?: any;
    };
  };
  dataZoom?: any;
}

// function TooltipsNameFormater(value: string | number) {
//   return moment(Number(value)).format('MM-DD HH:mm');
// }

function formatSpeedWithDecimals(num: number | string) {
  return formatSpeed(num, 1);
}

function Xformater(value: string | number) {
  return `${moment(Number(value)).format('MM-DD')}\n${moment(
    Number(value),
  ).format('HH:mm')}`;
}

function formatSeries({
  name,
  type = 'line',
  data,
  itemAndLineColor,
  areaStyle,
  yAxisIndex,
}: {
  name: string;
  type?: string;
  data: number[];
  itemAndLineColor: string;
  areaStyle: any;
  yAxisIndex: number;
}) {
  return {
    name,
    // localSpeed
    type: type || 'line',
    symbol: 'circle',
    showSymbol: false,
    yAxisIndex,
    smooth: true,
    data,

    lineStyle: {
      color: itemAndLineColor,
    },
    itemStyle: {
      color: itemAndLineColor,
    },
    areaStyle: areaStyle,
    ...(type === 'bar' && {
      barMaxWidth: 20,
    }),
    markLine: {
      symbol: ['none', 'none'],
      data: [
        {
          type: 'average', // 设置线类型为平均线
          lineStyle: {
            normal: {
              color: itemAndLineColor, // 设置线的颜色为绿色
              width: 1, // 设置线的宽度为2px
              type: 'dashed', // 设置线的类型为实线
            },
          },
          label: {
            formatter: '',
          },
        },
      ],
    },
  };
}

const colors = {
  maya: {
    color1: '#2B80FF',
    color2: '#7C3AED',
    color3: '#9D47FF',
    linear1: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: 'rgba(43, 128, 255, 0.2)',
      },
      {
        offset: 1,
        color: 'rgba(43, 128, 255, 0)',
      },
    ]),
    linear2: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: 'rgba(124, 58, 237, 0.2)',
      },
      {
        offset: 1,
        color: 'rgba(124, 58, 237, 0)',
      },
    ]),
    tooltips: {
      backgroundColor: '#21263E',
      textColor: '#fff',
    },
  },
  community: {
    color1: '#22EEE2',
    color2: '#7C3AED',
    color3: '#9D47FF',
    linear1: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: 'rgba(34, 238, 226, 0.2)',
      },
      {
        offset: 1,
        color: 'rgba(34, 238, 226, 0)',
      },
    ]),
    linear2: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: 'rgba(124, 58, 237, 0.2)',
      },
      {
        offset: 1,
        color: 'rgba(124, 58, 237, 0)',
      },
    ]),
    tooltips: {
      backgroundColor: '#25222B',
      textColor: '#fff',
    },
  },
};

const colorConfig =
  colors[UIVersion as keyof typeof colors] || colors.community;
export default function ChartTMP1({
  d1,
  d2,
  d3,
  isLoading,
  xformater = Xformater,
  //   tooltipsNameFormater = TooltipsNameFormater,
  showLoading = true,
  chartName,
  chartStyles,
  isOpenDataZoom,
  dataZoom,
  height = 200,
}: ChartTMP1Props) {
  const { t, i18n } = useTranslation();

  const chartData = useMemo(() => {
    return getChartDataWithInput(d1.data || [], d2?.data || [], d3?.data || []);
  }, [d1, d2, d3]);

  const options = useMemo(() => {
    const legendName = [d1.name];
    const series = [
      formatSeries({
        name: d1.name,
        type: d1.type,
        data: chartData.y1,
        itemAndLineColor: colorConfig.color1,
        areaStyle: {
          opacity: 0.8,
          color: colorConfig.linear1,
        },
        yAxisIndex: d1?.yAxisIndex || 0,
      }),
    ];
    if (d2) {
      legendName.push(d2.name);
      series.push(
        formatSeries({
          name: d2.name,
          type: d2.type,
          data: chartData.y2,
          itemAndLineColor: colorConfig.color2,
          areaStyle: {
            opacity: 0.8,
            color: colorConfig.linear2,
          },
          yAxisIndex:
            d2?.yAxisIndex === 0 ? d2?.yAxisIndex : chartName.y2 ? 1 : 0,
        }),
      );
    }
    if (d3) {
      legendName.push(d3.name);
      series.push(
        formatSeries({
          name: d3.name,
          type: d3.type,
          data: chartData.y3,
          itemAndLineColor: colorConfig.color3,
          areaStyle: {
            opacity: 0.8,
            color:
              d3.type !== 'line' ? colorConfig.color3 : colorConfig.linear2,
          },
          yAxisIndex:
            d3?.yAxisIndex === 0 ? d3?.yAxisIndex : chartName.y2 ? 1 : 0,
        }),
      );
    }

    let yAxis: any;
    if (chartName.y2) {
      yAxis = [
        {
          name: chartName.y1,
          nameTextStyle:
            chartStyles?.nameTextStyle?.y1 ||
            (i18n.language === 'en'
              ? {
                  padding: [0, 0, 0, 10],
                  align: 'center',
                }
              : {
                  align: 'center',
                }),
          splitLine: {
            lineStyle: {
              color: 'rgba(217, 217, 217, 0.2)',
            },
          },
          axisLabel: {
            formatter: d1?.axisLabelFormater || formatSpeedWithDecimals,
          },
        },
        {
          name: chartName.y2,
          nameTextStyle: chartStyles?.nameTextStyle?.y2 || {
            padding: [0, 20, 0, 0],
            align: 'center',
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(217, 217, 217, 0.2)',
            },
          },
          axisLabel: {
            formatter: d2?.axisLabelFormater || formatSpeedWithDecimals,
          },
        },
      ];
    } else {
      yAxis = {
        name: chartName.y1,
        nameTextStyle:
          chartStyles?.nameTextStyle?.y1 ||
          (!d2
            ? { align: 'left' }
            : i18n.language === 'en'
            ? {
                padding: [0, 0, 0, 10],
                align: 'center',
              }
            : { align: 'center' }),
        splitLine: {
          lineStyle: {
            color: 'rgba(217, 217, 217, 0.2)',
          },
        },
        axisLabel: {
          formatter: d1?.axisLabelFormater || formatSpeedWithDecimals,
        },
      };
    }

    const tooltips = {
      trigger: 'axis',
      backgroundColor: colorConfig.tooltips.backgroundColor,
      borderWidth: '0',
      textStyle: {
        color: colorConfig.tooltips.textColor,
      },

      formatter: function (params: any) {
        let tip = '';

        if (params != null && params.length > 0) {
          tip += params[0].name + '<br />';
          for (let i = 0; i < params.length; i++) {
            if (i !== 0 && !isMaya) {
              tip += '<br />';
            }
            let value = params[i].value;
            tip += `${params[i].marker} ${
              params[i].seriesName
            }: ${formatSpeedWithDecimals(value)} <br />${params[i].marker} ${t(
              'Average',
            )}: ${
              params[i].seriesName === d1.name
                ? (d1?.axisLabelFormater || formatSpeedWithDecimals)(
                    chartData.y1Average,
                  )
                : params[i].seriesName === d2?.name
                ? (d2?.axisLabelFormater || formatSpeedWithDecimals)(
                    chartData.y2Average,
                  )
                : (d3?.axisLabelFormater || formatSpeedWithDecimals)(
                    chartData.y3Average,
                  )
            }<br />`;
          }
        }
        return tip;
      },
    };
    return {
      tooltip: isMobile ? undefined : tooltips,
      grid: chartName?.y2
        ? isMobile
          ? {
              x: 70,
              y: 50,
              x2: 50,
              y2: isOpenDataZoom ? 80 : 40,
            }
          : {
              x: 60,
              y: 30,
              x2: 60,
              y2: isOpenDataZoom ? 80 : 40,
            }
        : isMobile
        ? {
            x: 60,
            y: 50,
            x2: 20,
            y2: isOpenDataZoom ? 80 : 40,
          }
        : {
            x: 60,
            y: 30,
            x2: 20,
            y2: isOpenDataZoom ? 80 : 40,
          },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: chartData.x.map((item) => xformater(item)),
      },
      legend: d2
        ? {
            data: legendName,
            textStyle: {
              color: '#fff',
            },
            right: chartName?.y2 ? (isMobile ? 0 : 180) : 0,
            // icon: 'circle',
            itemWidth: 10,
            itemHeight: 10,
            lineStyle: {
              width: 0,
            },
          }
        : undefined,
      yAxis,
      series,
      dataZoom: isOpenDataZoom
        ? dataZoom || [
            {
              type: 'inside',
              start: 0,
              end: 100,
            },
            {
              start: 0,
              end: 10,
            },
          ]
        : undefined,
    };
    console.log(dataZoom);
  }, [
    chartData,
    d1,
    d2,
    i18n.language,
    chartName,
    chartStyles,
    isOpenDataZoom,
    dataZoom,
  ]);

  return (
    <Wrapper
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      {showLoading && isLoading ? (
        <RowCenter>
          <Loading />
        </RowCenter>
      ) : (
        <ReactECharts
          option={options}
          notMerge={true}
          lazyUpdate={true}
          style={{ width: '100%', height: height }}
          // onChartReady={this.onChartReadyCallback}
          // onEvents={EventsDict}
          // opts={}
        />
      )}
    </Wrapper>
  );
}
