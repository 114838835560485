import classNames from 'classnames';
import { isMaya } from 'const';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

const StyledOverview = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
  overflow: hidden;
  background-image: url(${require('assets/img/overview.png')});
  background-position: center;
  background-size: cover;
  padding: 40px 0;
  flex-wrap: wrap;

  &.maya {
    background: #21263e;
    background-image: unset;
    border-top-left-radius: 0;
    gap: 0;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column; 
    padding: 0;
    
    > div {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
        padding: 0 10px !important;
        height: 50px;
        &:last-child {
            &::after {
                display: none;
            }
        }
        &::after {
            content: ' ';
            height: 1px;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0.02) 2%,
            rgba(255, 255, 255, 0.3) 30%,
                rgba(255, 255, 255, 0.3) 60%,
            rgba(255, 255, 255, 0.02) 98%
            );
        }

        >div {
            width: fit-content;
        }
    }
    `};

    > div {
      padding: 10px;
      position: relative;
    }
  }
  > div {
    &:not(:last-child) {
      border-right: 1px solid #666666;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  > div {
   &:not(:last-child) {
      border: none;
    }
   }
  `};
`;

export const OverviewItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0 20px;
  padding: 20px;
  height: 60px;
  width: 25%;
  flex-shrink: 0;

  ${({ theme }) => theme.mediaWidth.upToSmall`
  width: calc(50% - 10px);
  padding : 10px;
  `};
`;

export function Overview({ children }: { children: ReactNode }) {
  return (
    <StyledOverview className={classNames(isMaya && 'maya')}>
      {children}
    </StyledOverview>
  );
}
