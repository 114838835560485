import { OuterlinkSVG } from 'assets';
import Button from 'components/Button';
import Command from 'components/Command';
import { RowCenter } from 'components/Row';
import { isCommunity, TUTORIAL_LINK } from 'const';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TYPE } from 'theme';

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  gap: 40px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    gap: 20px;
    width: calc(100% - 20px);
    `};

  .tutorial {
    font-family: 'gemsbuck-title' !important;
  }
`;
export default function JoinContent({ className }: { className?: string }) {
  const { t } = useTranslation();
  return (
    <Block className={className}>
      <RowCenter>
        <TYPE.cGMain fontSize={48} mobileFz={24} uppercase>
          {t('How to join')}
        </TYPE.cGMain>
      </RowCenter>
      {/* <TYPE.desc
        fontSize={20}
        mobileFz={16}
        fontWeight={600}
        textAlign={'center'}
      >
        {t('runCmdJoin')}
      </TYPE.desc>
      <Command command="curl -sSf -L https://cysic.sh/join|sudo sh" /> */}
      {isCommunity && (
        <TYPE.desc
          marginTop={40}
          textAlign={'center'}
          fontSize={16}
          lineHeight={'20px'}
        >
          {t('homepageBottomDesc')}
        </TYPE.desc>
      )}
      <Button
        className="tutorial"
        onClick={() => {
          window.open(TUTORIAL_LINK);
        }}
      >
        {t('Detailed tutorial')}
        &nbsp;&nbsp;
        <OuterlinkSVG />
      </Button>
    </Block>
  );
}
